import { gql } from "@apollo/client";

export const GET_ALL_FAVOURITE_OFFERS = gql`
  query favoriteOffers($taskId: Int!) {
    favoriteOffers(taskId: $taskId) {
      count
      isLinkExpired
      shareOffers
      data {
        reserved
        hasDeal
        id
        offerId
        taskId
        offer {
          status
          offerData {
            establishmentType
            address
            realEstatePrice
            offerType
          }
          negotiators {
            count
            data {
              firstName
              lastName
            }
          }
          id
          geometry
          favoriteOfferId
          data
        }
      }
    }
  }
`;

export const GET_ALL_OFFERS = gql`
  query getAllOffers($offset: BoundedInt, $limit: BoundedInt, $taskId: Int!) {
    offers(pageInfo: { limit: $limit, offset: $offset }, taskId: $taskId) {
      boundaries
      geometries
      count
      data {
        data
        favoriteOfferId
        geometry
        recommended
        renOfferId
        id
        negotiators {
          count
          data {
            firstName
            lastName
          }
        }
        offerData {
          address
          establishmentType
          offerType
          realEstatePrice
        }
        status
      }
    }
  }
`;

export const GET_INTERNAL_OFFER_DATA = gql`
  query getInternalOfferData($pk: Int) {
    internalOffers(pk: $pk) {
      count
      data {
        data
      }
    }
  }
`;

export const GET_EXTERNAL_OFFER_DATA = gql`
  query getExternalOfferData($pk: Int, $taskId: Int!) {
    offers(pk: $pk, taskId: $taskId) {
      count
      data {
        renOfferId
        data
      }
    }
  }
`;

export const GET_COORDINATES_DATA = gql`
  query offerLocationData($longitude: Float!, $latitude: Float!) {
    offerLocationData(longitude: $longitude, latitude: $latitude) {
      data
    }
  }
`;

export const INTERNAL_OFFERS = gql`
  query internalOffers {
    internalOffers {
      count
      boundaries
      geometries
      data {
        data
        geometry
        id
        offerData {
          address
          establishmentType
          images
          offerArea
          offerBathroom
          offerRoom
          offerType
          realEstatePrice
        }
      }
    }
  }
`;
