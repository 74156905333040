import { gql } from "@apollo/client";

export const GET_BENEFECIARY_OFFERS = gql`
  {
    beneficiaryOffers {
      task {
        id
      }
      timeElapsed
      isLinkExpired
      beneficiaryData
      submitted
      count
      offers {
        data
        favoriteOfferId
        geometry
        id
        negotiators {
          count
          data {
            firstName
            lastName
          }
        }
        offerData {
          realEstatePrice
          offerType
          offerRoom
          offerBathroom
          offerArea
          establishmentType
          address
          images
        }
        status
      }
    }
  }
`;
