import { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import { Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import OSM from "ol/source/OSM";
import { defaults as defaultControls } from "ol/control";
import Text from "ol/style/Text";
import { boundingExtent, Extent } from "ol/extent";
import { fromLonLat } from "ol/proj";
import Point from "ol/geom/Point";
import Feature from "ol/Feature";

export const OSMLayer = new TileLayer({ visible: true, source: new OSM() });
OSMLayer.set("name", "OpenStreetMap");

export const OffersMap = ({
  offersBoundries,
  offersGeometries,
  setSelectedOffer,
  selectedOffer,
}) => {
  const mapRef = useRef<Map>();
  const domRef = useRef<HTMLDivElement | null>(null);
  const vectorLayerRef = useRef<VectorLayer>();

  useEffect(() => {
    if (mapRef.current) return;

    const vectorSource = new VectorSource({});

    offersGeometries?.forEach((offer) => {
      const coords =
        offer?.geometry?.coordinates ??
        offer?.geometry?.geometries?.at(0)?.coordinates;
      const point = new Point(fromLonLat(coords));
      const feature = new Feature({
        geometry: point,
        offerId: offer.offer_id,
        offerPrice: offer.offer_price,
      });

      vectorSource.addFeature(feature);
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: (feature) => {
        const fontSize = 12;
        const offerPrice = feature.get("offerPrice");

        return new Style({
          text: new Text({
            text: `${offerPrice} SAR`,
            font: `bold ${fontSize}px Arial`,
            fill: new Fill({
              color: "#000000",
            }),
            backgroundFill: new Fill({
              color: "#ffffff",
            }),
            backgroundStroke: new Stroke({
              color: "#ffffff",
              width: 2,
            }),
            padding: [10, 20, 10, 20],
            textAlign: "center",
            textBaseline: "middle",
            overflow: true,
          }),
        });
      },
    });

    vectorLayerRef.current = vectorLayer;

    const projectedBoundary = offersBoundries?.coordinates
      ?.at(0)
      .map((coord) => fromLonLat(coord));

    const boundaryExtent = boundingExtent(projectedBoundary ?? []);

    const mapView = new View({
      center: [5200224.773151715, 2831517.163862219],
      zoom: 5,
    });

    mapRef.current = new Map({
      layers: [OSMLayer, vectorLayer],
      view: mapView,
      target: domRef?.current || undefined,
      controls: defaultControls({
        zoom: false,
        attribution: false,
        rotate: false,
      }),
    });

    mapRef.current.once("rendercomplete", () => {
      const size = mapRef.current?.getSize();
      if (size) {
        mapView.fit(boundaryExtent, {
          size: size,
          padding: [100, 100, 100, 100],
          duration: 1000,
        });
      }
    });

    mapRef.current.on("singleclick", (event) => {
      mapRef.current?.forEachFeatureAtPixel(event.pixel, (feature) => {
        const clickedFeatureId = feature.get("offerId");
        setSelectedOffer(`${clickedFeatureId}`);
      });
    });
  }, [offersGeometries, offersBoundries]);

  useEffect(() => {
    if (!mapRef.current) return;
    if (!vectorLayerRef.current || !selectedOffer) return;

    const source = vectorLayerRef.current.getSource() as VectorSource;

    source.getFeatures().forEach((feature) => {
      const fontSize = 12;
      const offerPrice = feature.get("offerPrice");
      const isHighlighted = feature.get("offerId") === Number(selectedOffer);
      let selectedFeatureExtent: Extent | null = null;

      feature.setStyle(
        new Style({
          text: new Text({
            text: `${offerPrice} SAR`,
            font: `bold ${fontSize}px Almarai`,
            fill: new Fill({
              color: isHighlighted ? "#FFFFFF" : "#000000",
            }),
            backgroundFill: new Fill({
              color: isHighlighted ? "#000000" : "#ffffff",
            }),
            backgroundStroke: new Stroke({
              color: "#ffffff",
              width: 2,
            }),
            padding: [10, 20, 10, 20],
            textAlign: "center",
            textBaseline: "middle",
            overflow: true,
          }),
        })
      );
      if (isHighlighted) {
        selectedFeatureExtent = feature.getGeometry()?.getExtent() || null;
      }
      if (selectedFeatureExtent) {
        mapRef?.current?.getView().fit(selectedFeatureExtent, {
          padding: [150, 150, 150, 150],
          maxZoom: 10,
          duration: 1000,
        });
      }
    });

    mapRef.current.render();
  }, [selectedOffer]);

  return (
    <Box sx={{ width: "100%", height: "700px", position: "sticky", top: 130 }}>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
        ref={domRef}
      ></div>
    </Box>
  );
};
