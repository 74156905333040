import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { useTranslation } from "react-i18next";

import { useNavigate, useParams } from "react-router";

export const InternalOfferTable = ({ offers, onRowClick, selectedOffer }) => {
  const { t } = useTranslation();
  const { taskId } = useParams();
  const navigate = useNavigate();

  if (!offers || offers.length === 0) {
    return <div>{t("No offers available")}</div>;
  }
  const formatAddress = (address) => {
    return `${address.street}, ${address.district} , ${address.city} `;
  };
  const handleEditOffer = (offerId) => {
    navigate(
      `/app/negotiator/tasks/${taskId}/edit-offer/${offerId}?offerLocation=internal`
    );
  };

  return (
    <Card variant="outlined">
      <TableContainer>
        <Table sx={{ minWidth: 600 }} aria-label="Offers Table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Offer Id")}</TableCell>
              <TableCell>{t("Property type")}</TableCell>
              <TableCell>{t("Offer type")}</TableCell>
              <TableCell>{t("Address")}</TableCell>
              <TableCell>{t("Price")}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((offer, index) => (
              <TableRow
                key={index}
                onClick={() => onRowClick(offer.id)}
                sx={{
                  cursor: "pointer",
                  backgroundColor:
                    selectedOffer === offer?.id ? "#f0f0f0" : "transparent",
                }}
              >
                <TableCell>{offer?.id}</TableCell>
                <TableCell>
                  {offer?.offerData?.establishmentType ?? "---"}
                </TableCell>
                <TableCell>
                  {offer?.offerData?.offerType?.map((item, index) => (
                    <div key={index}>{item ?? "---"}</div>
                  ))}
                </TableCell>
                <TableCell>
                  {formatAddress(offer?.offerData?.address) ?? "---"}
                </TableCell>
                <TableCell>
                  {offer?.offerData?.realEstatePrice ?? "---"}
                </TableCell>

                <TableCell sx={{ display: "flex" }}>
                  <IconButton onClick={() => handleEditOffer(offer?.id)}>
                    <EditIcon sx={{ color: "#8C8C8C" }} fontSize="medium" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
};
