import { useState } from "react";
import { IconButton, Menu, Typography, Box, Badge } from "@mui/material";
import { NotificationsOutlined as Bell } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATIONS } from "@/shared/graphql";
import {
  DELETE_NOTIFICATION,
  MARK_NOTIFICATIONS_AS_READ,
} from "@/shared/graphql/mutations/notifications";
import { NotificationItem } from "./NotificationItem";

export const Notifications = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data, loading, refetch } = useQuery(GET_NOTIFICATIONS, {
    variables: {
      pageInfo: { orderBy: "-timestamp" },
    },
  });

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION);
  const [markNotificationAsRead] = useMutation(MARK_NOTIFICATIONS_AS_READ);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteNotification = (pk: string) => {
    deleteNotification({
      variables: {
        pk,
      },
    }).then(() => {
      refetch();
    });
  };

  const markReadNotification = (pk?: string) => {
    if (pk === "all") {
      markNotificationAsRead({
        variables: {},
      }).then(() => {
        refetch();
      });
      return;
    }
    markNotificationAsRead({
      variables: {
        pk,
      },
    }).then(() => {
      refetch();
    });
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        size="large"
        sx={{
          width: 60,
          height: 60,
        }}
      >
        <Badge
          badgeContent={data?.notifications?.unreadCount || 0}
          color="error"
          sx={{
            "& .MuiBadge-badge": {
              fontSize: 12,
              height: 16,
              minWidth: 16,
              top: 2,
              right: 2,
            },
          }}
        >
          <Bell />
        </Badge>
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleNotificationsClose}
        PaperProps={{
          sx: {
            width: 400,
            maxHeight: 480,
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            borderRadius: 2,
            overflow: "hidden",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid",
            borderColor: "divider",
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 600 }}>
            {t("Notifications")} ({data?.notifications?.count || 0})
          </Typography>
        </Box>

        <Box sx={{ overflowY: "auto", maxHeight: 400 }}>
          {loading ? (
            <Box sx={{ p: 2, textAlign: "center" }}>
              <Typography color="text.secondary">
                {t("Loading notifications...")}
              </Typography>
            </Box>
          ) : data?.notifications?.data?.length === 0 ? (
            <Box sx={{ p: 2, textAlign: "center" }}>
              <Typography color="text.secondary">
                {t("No notifications yet")}
              </Typography>
            </Box>
          ) : (
            data?.notifications?.data?.map((notification: any) => (
              <NotificationItem
                key={notification?.id}
                handleNotificationsClose={handleNotificationsClose}
                notificationData={notification}
                handleDeleteNotification={handleDeleteNotification}
                markReadNotification={markReadNotification}
              />
            ))
          )}
        </Box>
      </Menu>
    </div>
  );
};
