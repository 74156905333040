import React, { useState } from "react";
import { Box, Typography, Grid, Button, Container, Modal } from "@mui/material";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import EastIcon from "@mui/icons-material/East";
import PropertyFields from "./OfferDetails/PropertyFields";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import EmptyOffer from "@/assets/emptyOffer.png";
import "swiper/css";

const OfferDetails = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const selectedCard = location.state?.offer;
  const taskId = location.state?.taskId;
  const [openFullscreen, setOpenFullscreen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  if (!selectedCard) {
    return <Typography>No offer details available</Typography>;
  }

  const formatAddress = (address) => {
    if (!address) return "---";
    return `${address.street}, ${address.district}, ${address.city}`;
  };

  const handleChooseOffer = () => {
    navigate("../confirm-offer", {
      state: { offer: selectedCard, taskId },
      replace: true,
    });
  };

  const handleViewMap = () => {
    navigate("../offer-map", {
      state: {
        coordinates: selectedCard?.data?.completingOffer?.location?.coordinate,
        offer: selectedCard,
        taskId,
      },
    });
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setOpenFullscreen(true);
  };

  const handleCloseFullscreen = () => {
    setOpenFullscreen(false);
  };

  return (
    <Container>
      <Typography
        variant="h5"
        fontWeight="bold"
        sx={{
          mb: 3,
          fontSize: { xs: "18px", md: "24px" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <NavLink to={"../"}>
          <EastIcon fontSize="medium" sx={{ mr: 1 }} />
        </NavLink>
        {t("Offer Details")}
      </Typography>

      <Grid container spacing={1}>
        <Grid
          item
          md={12}
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {selectedCard?.offerData?.images?.length == 0 ? (
            <Box
              component="img"
              src={EmptyOffer}
              sx={{
                borderRadius: "8px",
                width: { xs: "100%", md: "100%" },
                height: { xs: "100%", md: "100%" },
                objectFit: "contain",
              }}
            />
          ) : (
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={4}
              slidesPerView={3}
              navigation={true}
              loop={true}
              pagination={{ clickable: true }}
              className="custom-swiper"
            >
              {selectedCard?.offerData?.images?.map((image, index) => (
                <SwiperSlide key={index}>
                  <Box
                    component="img"
                    src={image}
                    alt={`Slide ${index + 1}`}
                    sx={{
                      borderRadius: "8px",
                      width: "100%",
                      height: { xs: "150px", md: "250px" },
                      objectFit: "cover",
                      cursor: "pointer",
                    }}
                    onClick={() => handleImageClick(image)}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Grid>

        {/* Fullscreen Image Modal */}
        <Modal
          open={openFullscreen}
          onClose={handleCloseFullscreen}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              maxWidth: "90vw",
              maxHeight: "90vh",
            }}
          >
            <Box
              component="img"
              src={selectedImage}
              alt="Fullscreen"
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <Button
              variant="contained"
              onClick={handleCloseFullscreen}
              sx={{
                position: "absolute",
                top: 16,
                right: 16,
                bgcolor: "rgba(0, 0, 0, 0.5)",
                "&:hover": {
                  bgcolor: "rgba(0, 0, 0, 0.7)",
                },
              }}
            >
              {t("close")}
            </Button>
          </Box>
        </Modal>

        <Grid
          item
          xs={12}
          sx={{ display: "flex", flexDirection: "column", gap: 1 }}
        >
          <Typography variant="h4" fontWeight={"700"}>
            {selectedCard?.offerData.realEstatePrice} {t("SARs")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              flexDirection: "row",
            }}
          >
            <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
              {`${selectedCard.offerData?.establishmentType} |`}
            </Typography>
            {selectedCard.offerData?.offerRoom && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerRoom}${t("rooms")} |`}
              </Typography>
            )}
            {selectedCard.offerData?.offerBathroom && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerBathroom} ${t("bathrooms")} |`}
              </Typography>
            )}
            {selectedCard.offerData?.offerArea && (
              <Typography sx={{ fontSize: { md: 18, xs: 16 } }}>
                {`${selectedCard.offerData?.offerArea} ${t("sqmeters")}`}
              </Typography>
            )}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <FmdGoodOutlinedIcon />
            <Typography>
              {formatAddress(selectedCard?.offerData?.address)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{
              mt: 1,
              py: 1.2,
              display: "flex",
              alignItems: "center",
              gap: 1,
              borderRadius: "8px",
            }}
            onClick={handleViewMap}
          >
            <FmdGoodOutlinedIcon />
            {t("View on Map")}
          </Button>

          <Typography variant="body2" fontWeight={"400"} sx={{ p: 2 }}>
            {selectedCard?.data?.completingOffer?.offerData?.description}
          </Typography>

          <PropertyFields selectedOffer={selectedCard} />

          <Box sx={{ my: 3 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              sx={{ py: 1.2 }}
              onClick={handleChooseOffer}
            >
              {t("Start Negotiation")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OfferDetails;
