// import { Grid } from "@mui/material";
// import { OfferDetails } from "./OfferDetails";
// import { OffersTable } from "./OffersTable";
// import { OffersMap } from "./OffersMap";

import { OffersEmptyState } from "@/components/OffersEmptyState";
import {
  Box,
  Button,
  Grid,
  Pagination,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import AddIcon from "@mui/icons-material/Add"; // Import Material-UI Add icon
import { GET_ALL_OFFERS, INTERNAL_OFFERS } from "@/shared/graphql";
import { useQuery } from "@apollo/client";
import { Loading } from "@/components/Loading";
import { OffersTable } from "./OffersTable";
import { OfferDetails } from "./OfferDetails";
import { OffersMap } from "./OffersMap";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { InternalOfferTable } from "./OffersTable/internalOfferTable";

export const Offers = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);

  const [selectedOffer, setSelectedOffer] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const pageLimit = 8;
  const navigate = useNavigate();
  const { taskId } = useParams();
  const handleClick = () => {
    if (taskId) {
      navigate(`/app/negotiator/tasks/${taskId}/add-offer`);
    } else {
      console.error("Task ID is missing from the URL");
    }
  };

  const handleRowClick = (offer) => {
    setSelectedOffer(offer);
  };
  const {
    data,
    refetch: refetchOffers,
    loading,
    error,
  } = useQuery(GET_ALL_OFFERS, {
    variables: {
      offset: Number(searchParams.get("page") || 1) * pageLimit - pageLimit,
      limit: pageLimit,
      taskId: taskId,
    },
    onCompleted: (data) => {
      const offers = data?.offers?.data;
      setSelectedOffer(offers?.at(0)?.id);
    },
  });

  const {
    data: internalOffersData,
    loading: internalOffersLoading,
    error: internalOffersError,
  } = useQuery(INTERNAL_OFFERS);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
      </div>
    );
  };

  const offers = data?.offers?.data;
  const offersCount = data?.offers?.count;
  const offersBoundries = data?.offers?.boundaries;
  const offersGeometries = data?.offers?.geometries;
  const numberOfPages = Math.ceil(offersCount / pageLimit);

  if (loading) return <Loading />;
  if (error) return <></>;

  const handlePagination = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setSearchParams((params) => {
      params.set("page", `${page}`);
      return params;
    });
  };

  return (
    <Box sx={{ py: 3, textAlign: "center" }}>
      <Grid item xs={12} lg={8}>
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: "20px",
            textAlign: "start",
            p: 2,
          }}
        >
          {t("Offers")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            variant="standard"
            sx={{ borderBottom: "1px solid #ddd" }}
          >
            <Tab
              label={t("Completed")}
              sx={{ fontSize: "12px", fontWeight: 700 }}
            />
            <Tab
              label={t("Not Completed")}
              sx={{ fontSize: "12px", fontWeight: 700 }}
            />
          </Tabs>
          {offersCount > 0 && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              sx={{ px: 1.5 }}
              onClick={handleClick}
            >
              {t("Add Offer")}
            </Button>
          )}
        </Box>
      </Grid>

      <Grid container spacing={3}>
        <Grid item xs={12} lg={12}>
          <TabPanel value={tabIndex} index={0}>
            {offersCount > 0 ? (
              <>
                <Grid container spacing={3}>
                  <Grid item xs={12} lg={8}>
                    <OfferDetails
                      offer={offers.find((offer) => offer.id === selectedOffer)}
                      refetchOffers={refetchOffers}
                    />
                    <OffersTable
                      offers={offers || []}
                      refetchOffers={refetchOffers}
                      onRowClick={handleRowClick}
                      selectedOffer={selectedOffer}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <OffersMap
                      offersBoundries={offersBoundries ?? []}
                      offersGeometries={offersGeometries ?? []}
                      setSelectedOffer={setSelectedOffer}
                      selectedOffer={selectedOffer}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={6} mt={2}>
                  <Pagination
                    count={numberOfPages || 1}
                    page={Number(searchParams.get("page") || 1)}
                    onChange={handlePagination}
                    variant="outlined"
                    shape="rounded"
                  />
                </Grid>
              </>
            ) : (
              <>
                <OffersEmptyState />
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{ py: 1, px: 8 }}
                  onClick={handleClick}
                >
                  {t("Add Offer")}
                </Button>
              </>
            )}
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            {internalOffersLoading ? (
              <Loading />
            ) : internalOffersError ? (
              <Typography color="error">{t("Error loading offers")}</Typography>
            ) : (
              <InternalOfferTable
                offers={internalOffersData?.internalOffers?.data || []}
                onRowClick={handleRowClick}
                selectedOffer={selectedOffer}
              />
            )}
          </TabPanel>
        </Grid>
      </Grid>
    </Box>
  );
};
