import { gql } from "@apollo/client";

export const GET_NOTIFICATIONS = gql`
  query GetNotifications($pageInfo: PageInfo) {
    notifications(pageInfo: $pageInfo) {
      data {
        description
        id
        level
        taskId
        unread
        timesince
        verb
      }
      count
      unreadCount
    }
  }
`;
